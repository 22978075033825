* {
  margin: 0;
  padding: 0;
}

html {
  font-family: Helvetica, sans-serif;
  background: steelblue;
  height: 100%;
}

.site-nav {
  padding: 10px;
  background: #fff;
  display: flex;
  align-items: center;
}

.site-nav ul {
  list-style-type: none;
  justify-self: flex-end;
}

.site-nav li {
  height: 25px;
  margin-right: 0px;
  border-right: 1px solid #aaa;
  padding: 0 10px;
  display: inline-block;
}

.site-nav li:last-child {
  border-right: none;
}

.site-nav li:last-child {
  border-right: none;
}

.site-nav li a {
  text-decoration: none;
  color: #666;
  font-size: 20px;
  line-height: 20px;
  font-family: "IBM Plex Serif", serif;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}

.site-nav li a:hover {
  color: #315c80;
}

.site-nav li.active a {
  color: #315c80;
  text-decoration: underline;
}

.site-header {
  padding: 25px 10px 0;
  max-width: 900px;
}

.site-header h1 {
  color: white;
  font-family: "IBM Plex Serif", serif;
  font-weight: normal;
  font-size: 3rem;
  line-height: 2.5rem;
  position: relative;
  display: flex;
  align-items: center;
  transition: all 0.5s ease;
}

.site-header h1 img {
  max-width: 100px;
  border-radius: 50%;
  border: 1px solid white;
  margin-right: 5px;
}

.site-header a {
  color: white;
}

.site-header .description {
  padding-top: 1rem;
  font-size: 1.15rem;
  line-height: 1.61rem;
}

p, h2 {
  font-family: "IBM Plex Serif", serif;
  color: white;
  font-weight: normal;
}

section {
  padding: 0 10px 25px;
  margin-top: 20px;
}

section .transition-group {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 10px;
  justify-content: flex-start;
}

section .transition-group img {
  min-width: 100%;
}

@media screen and (min-width: 390px) {
  .site-header .description {
    padding-top: 1rem;
    font-size: 1.25rem;
    line-height: 1.75rem;
    color: #fff;
  }
}

@media screen and (min-width: 1000px) {
  section {
    position: relative;
    padding: 0 25px 25px;
  }
  section h2 {
    margin-right: 10px;
  }
  .site-nav {
    padding: 10px 25px;
  }
  .site-nav li {
    padding: 0 20px;
    overflow: visible;
    position: relative;
  }
  .site-nav li a {
    font-size: 25px;
    line-height: 25px;
  }
  .site-nav li.anchor a {
    font-size: 30px;
    top: 5px;
    position: absolute;
  }
  .site-nav li a:hover {
    letter-spacing: 5px;
  }
  .site-nav li.anchor a:hover {
    color: black;
    letter-spacing: 0;
  }
  .site-header {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: 40px;
    align-items: center;
    padding: 25px 25px 0;
  }
  .site-header .description {
    padding-top: 0;
  }
}