.card {
  height: 300px;
  background: #fff;
  transition: all 0.25s ease-in-out;
  border-radius: 5px;
  overflow: hidden;
}

.card-entering {
  opacity: 0;
  transform: translate3d(0, 40px, 0);
}

.card-entered {
  opacity: 1;
  transform: translate3d(0, 0px, 0);
}

.card-exiting {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.card-exited {
  opacity: 0;
  transform: translate3d(0, 40px, 0);
}
